import React, { useState, useEffect } from "react";
import { useJwt } from "react-jwt";
import { OfferService } from "../../services/OfferService";
import Card from "@mui/material/Card";
import { Button, CardContent, TablePagination } from "@mui/material";
import Typography from "@mui/material/Typography";
import { NumericFormat } from "react-number-format";
import "../../styles/ListOffer.css";

const token = sessionStorage.getItem("token");
const email = sessionStorage.getItem("email");

function DashboardOffer() {
  const { decodeToken, isExpired } = useJwt("token");

  const offerService = new OfferService();
  const [offers, setOffers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const findByState = await LoadState("NUEVO");
      setOffers(findByState);
      setLoading(false);
    })();
  }, []);

  async function Load() {
    const responseFindByEmail = await offerService.listOffer();
    return responseFindByEmail;
  }

  async function LoadState(state) {
    try{
    const responseFindByState = await offerService.findByState(state);
    return responseFindByState;
    }catch(err){
      console.error("Ha ocurrido un problema al intentar recuperar las ofertas agendadas",err)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSchedule = (id) => {
    console.log("ID SELECT", id);
    const formDataOffer =  new FormData()
    formDataOffer.append("idOffer",id)
    formDataOffer.append("emailUser",JSON.parse(email))
    const selectOffer = offerService.selectOffer(formDataOffer)
    console.log(selectOffer)
    window.location.reload();
  };

  const obtenerFechaSinTiempo = (isoDateString) => {
    const index = isoDateString.indexOf("T");
    if (index !== -1) {
      return isoDateString.slice(0, index);
    } else {
      return isoDateString;
    }
  };

  console.log("OFFERS", offers);

  return (
    <div className="dashboard-content">
      <div className="container-card">
        {offers
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((element, index) => (
            <Card variant="outlined" sx={{ width: 500, marginBottom: "20px" }}>
              <CardContent>
                <Typography variant="h5" component="div">
                  {element.name}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  <strong>Fecha:</strong> {obtenerFechaSinTiempo(element.date)}
                  <br />
                  <strong>Hora:</strong> ({element.startTime} -{" "}
                  {element.finalDate})
                </Typography>
                <Typography variant="body2">
                  <h5>Decripción:</h5>
                  {element.description}
                </Typography>
                <br />
                <Typography variant="body2">
                  <strong>Tarifa: </strong>
                  $
                  <NumericFormat
                    displayType="text"
                    value={element.price}
                    allowLeadingZeros
                    thousandSeparator=","
                  />
                </Typography>
              </CardContent>
              <Button
                id="schedule"
                variant="outlined"
                onClick={() => handleSchedule(element.id)}
              >
                Agendar
              </Button>
            </Card>
          ))}
        <TablePagination
          rowsPerPageOptions={[2]}
          component="div"
          count={offers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}

export default DashboardOffer;
